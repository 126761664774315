import { useState, useRef } from 'react';
import './App.css';

function App() {


  const [ response, setResponse ] = useState("");
  const user_input_ref = useRef(null)

  const url = 'https://nuuvforixdhniayjgxir.supabase.co/functions/v1/hello-world'; // Replace with your actual URL

  let make_query = () => {
    let user_input =  user_input_ref.current.value
    if (user_input.length == 0) return;

    const data = { query: user_input}; // Replace 'Your query data' with your actual data
    let res = fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }

        let txt = await res.text()
        console.log(JSON.parse(txt)[0].content)
        setResponse(JSON.parse(txt)[0].content)

        return res; // If the response is in JSON format
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  let handleKeyPress = (event) => {
    console.log(event)
    if (event.key === 'Enter') {
      make_query();
    }
  }

  return (
    <>

      <div className='h-full px-10 pt-10'>
        <div className="bg-gradient-to-r from-red-500 to-blue-500 bg-cover p-5 text-center font-mono text-4xl text-gray-300">
          industri<span className="text-white">ai</span>
        </div>

        <div className="grid grid-cols-4 align-center mt-5 mx-auto">
          <input autoFocus='true' ref={user_input_ref} onKeyDown={handleKeyPress} placeholder="What's the LOTO SOP for the conveyer?" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 col-span-3" />
          <button 
            onClick={() => { make_query() }} 
            className="bg-blue-500 text-white sm:px-1 md:px-4 py-2 rounded-md hover:bg-blue-600 opacity-90 ml-3"
            >search</button>
        </div>

      { response.length ?
        <p className='text-lg mt-5 p-2 text-black-200 border border-gray-300 rounded-md'>
          {response}
        </p>
        : <></>
      }
      </div>
    </>
  )


}

export default App;
